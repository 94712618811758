/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState } from "react";
import {
  World,
  Engine,
  Render,
  Events,
  Composite,
  Bodies,
  Runner,
  Body,
} from "matter-js";
import ballAudio from "../../assets/sound/sounds/ball.wav";
import Plinko from "./game/plinko";
import ManualTabToBet from "./manualtabtobet";
import AutoTabToBet from "./autotabtobet";
import { PARTICLE } from "./constants/bodies";
import { plinkoInstance } from "../../config/axiosConfig";
import livestats from "../../assets/images/game/live.png";
import fairnessicon from "../../assets/images/game/fairnessicon.svg";
import "./index.css";
import { Form, Modal, Tabs, Tab } from "react-bootstrap";
import FainessPopup from "./fainesspopup";
import LiveStats from "./livestatspopup";
import click from "../../assets/sound/click.mp3";
import win from "../../assets/sound/win.wav";
import { getMultiplierSound } from "../../pages/plinko/game/config/multipliers";
import { useAuthStore } from "../../store/auth";

const MS_IN_SECOND = 4000;
const FPS = 60;
let engine;
let particles;
let plinkos;
let clearChips;
let autoBet;
let activeInfinite;

function GameEngine() {
  const { user, setUser } = useAuthStore((state) => state);
  const [fairness, setFairness] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [stats, setStats] = useState(false);
  const [finalResult, setFinalResult] = useState();
  const [winMultiplierBetValue, setWinMultiplierBetValue] = useState();
  const [gameStates, setgameStates] = useState({
    ROWS: 16,
    betdisabeled: false,
    plinkoradius: 5,
    particleradius: 8,
    ROW_ADJUSTMENT: 0.9,
    COL_ADJUSTMENT: 0.99,
    CANVAS_WIDTH: 800,
    CANVAS_HEIGHT: 550,
    CANVAS_COLOR: "transparent",
    TIMESTEP: MS_IN_SECOND / FPS,
    PARTICLE,
  });
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [betAmount, setBetAmount] = useState(50);
  const [risk, setRisk] = useState("Low");
  const [clientSeed, setClientSeed] = useState("");
  const [serverWinBoxes, setServerWinBoxes] = useState([]);
  const [autoManual, setAutoManual] = useState(false);
  const [numOfBets, changeNumOfBets] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [serverSeed, setServerSeed] = useState();
  const [serverNonce, setNonce] = useState();
  const [volume, setVolume] = useState(true);
  const [winTextResult, setWinTextResult] = useState();
  const [toggleAnimation, setToggleAnimation] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [lastMultipliers, setLastMultipliers] = useState([]);
  const [testMode, setTestMode] = useState(
    localStorage.getItem("testmode") === "true"
  );
  const [key, setKey] = useState("Manual");
  const firstTimeBalance = 1000;
  let activeAutoBets = false;

  const handlestatsClose = () => {
    setStats(false);
  };
  const handleCloseFairness = () => {
    setFairness(false);
  };

  const handlestatsShow = () => {
    setStats(true);
  };
  const handleShow = () => {
    setFairness(true);
  };

  const fairnessCalculate = async () => {
    await plinkoInstance()
      .post("/verify", {
        param: {
          clientSeed: clientSeed,
          serverSeed: serverSeed,
          nonce: serverNonce,
        },
      })
      .then((res) => {
        setFinalResult(res?.data?.result);
      });
  };

  const genrateClientSeed = (length = 20) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setClientSeed(result);
  };

  useEffect(() => {
    genrateClientSeed();
  }, []);

  const createCanvas = () => {
    const container = document.getElementById("plinko");
    container.innerHTML = "";
    engine = Engine.create(container);
    engine.gravity.y = 1.7;
    const render = Render.create({
      element: container,
      bounds: {
        max: {
          y: gameStates.CANVAS_HEIGHT,
          x: gameStates.CANVAS_WIDTH,
        },
        min: {
          y: 0,
          x: 0,
        },
      },
      options: {
        background: gameStates.CANVAS_COLOR,
        hasBounds: true,
        width: gameStates.CANVAS_WIDTH,
        height: gameStates.CANVAS_HEIGHT,
        wireframes: false,
      },
      engine,
    });
    const runner = Runner.create();
    Runner.run(runner, engine);
    Render.run(render);
  };
  //Create particles
  const addBall = useCallback(
    (nonce, serverSeed, amount, transactionId, seed, riskname) => {
      const ballSound = new Audio(ballAudio);
      ballSound.volume = 0.0;
      ballSound.currentTime = 0;
      ballSound.play();
      const ballX = Math.floor(Math.random() * (445 - 360 + 1)) + 360;
      const ballColor = PARTICLE.FILL;
      const ball = Bodies.circle(ballX, 40, gameStates.particleradius, {
        restitution: PARTICLE.RESTITUTION,
        friction: PARTICLE.FRICTION,
        label: `ball|${nonce}:${serverSeed}:${transactionId}:${amount}:${seed}:${riskname}`,
        id: new Date().getTime(),
        frictionAir:
          gameStates?.ROWS === 8 || gameStates?.ROWS === 9 ? 0.1 : 0.09,
        collisionFilter: {
          group: -1,
        },
        render: {
          fillStyle: ballColor,
        },
        isStatic: false,
      });
      Body.setDensity(ball, PARTICLE.DENSITY);
      Composite.add(engine.world, ball);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gameStates.ROWS]
  );

  async function onCollideWithMultiplier(ball, multiplier) {
    const ballValue = ball.label.split("|")[1];
    let pgdId = multiplier?.id;
    let pgd = multiplier.id;
    pgd = `peg${pgd}`;
    const multiplierValue = document.getElementsByClassName(pgd)[0].innerText;
    const multiplierSong = new Audio(getMultiplierSound(multiplierValue));
    multiplierSong.currentTime = 0;
    multiplierSong.volume = 0.2;
    multiplierSong.play();
    setLastMultipliers((prev) => {
      if (prev.length === 6) {
        prev.pop();
        prev.unshift(multiplierValue);
      } else {
        prev.unshift(multiplierValue);
      }

      return prev;
    });
    setWinTextResult(multiplierValue);
    setToggleAnimation(true);
    setTimeout(() => {
      setToggleAnimation(false);
    }, 2000);
    setTimeout(() => {
      setWinTextResult();
    }, 2400);
    playAudio("win");
    setgameStates({
      ...gameStates,
      [pgd]: true,
    });
    setTimeout(() => {
      setgameStates({
        ...gameStates,
        [pgd]: false,
      });
    }, 300);

    const gameData = ballValue.split(":");
    if (gameData.length === 0) return;
    const [nonce, serverSeed, transactionId, amount, seed, riskname] =
      gameData || [];
    ball.collisionFilter.group = 2;
    World.remove(engine.world, ball);
    if (autoBet <= 0) {
      setIsGameRunning(false);
      setIsDisabled(false);
    }
    const checkTestMode =
      localStorage.getItem("testmode") === "true" ||
      localStorage.getItem("testmode") === true
        ? true
        : false;
    if (!checkTestMode) {
      await increaseXAmount(
        multiplierValue,
        amount,
        transactionId,
        serverSeed,
        nonce,
        seed,
        pgdId,
        riskname
      );
    }
  }

  async function onBodyCollision(event) {
    const pairs = event.pairs;
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair;
      if (bodyA.label === "plinko" || bodyB.label === "plinko") {
        bodyA.render.lineWidth = 15;
        setTimeout(() => {
          bodyA.render.lineWidth = 0;
        }, 200);
      }
      if (bodyB.label.includes("ball") && bodyA.label.includes("pocket"))
        await onCollideWithMultiplier(bodyB, bodyA);
    }
  }

  const playAudio = (type) => {
    let sound = localStorage.getItem("sound");
    if (sound && sound === "true") {
      if (type) {
        const audioEl = document.getElementsByClassName(`audio-${type}`)[0];
        if (audioEl) {
          audioEl.play();
        }
      }
    }
  };

  const autoBetStarted = async (riskName) => {
    if (numOfBets > 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    if (isGameRunning) {
      window.parent.postMessage("gameRunning", "*");
      return;
    }

    if (!testMode && !user) {
      window.parent.postMessage("loginRequest", "*");
      return;
    }
    if (!testMode) {
      if (user && user?.wallet < betAmount) {
        window.parent.postMessage("walletUpdate", "*");
        return;
      }
    }
    try {
      setIsGameRunning(true);
      if (testMode) {
        playAudio("click");
        setNonce(0);
        addBall(0);
        setWinMultiplierBetValue(betAmount);
        return;
      }
      const res = await plinkoInstance().post("/createPlinkoGame", {
        amount: betAmount,
        risk,
        rows: gameStates?.ROWS,
        clientSeed,
        mode: testMode,
      });
      playAudio("click");
      const { nonce, serverSeed, amount, transactionId } = res.data;
      setNonce(nonce);
      setServerSeed(serverSeed);
      addBall(nonce, serverSeed, amount, transactionId, clientSeed, risk);
    } catch (error) {
      window.parent.postMessage(
        { type: "errorResponse", msg: error?.response?.data?.message },
        "*"
      );
      if (key === "Auto") {
        handleCancel();
      }
      setIsGameRunning(false);
      setgameStates({
        ...gameStates,
        betdisabeled: false,
      });
    }
  };
  const changeBetCount = (cnt) => {
    changeNumOfBets(parseInt(cnt));
  };
  var newnumOfBets = numOfBets;
  const increaseXAmount = async (
    xamount,
    betAmnt,
    transactionId,
    serverSeed,
    nonce,
    clientSeed,
    multiplierId,
    riskname
  ) => {
    try {
      setWinMultiplierBetValue(betAmnt);
      const res = await plinkoInstance().post("/increaseXAmount", {
        param: {
          xamount: xamount,
          betAmnt: betAmnt,
          transactionId: transactionId,
          clientSeed: clientSeed,
          serverSeed: serverSeed,
          nonce: nonce,
          row: gameStates.ROWS,
          multiplierId: multiplierId,
          risk: riskname,
        },
      });
      setUser({ ...user, wallet: res.data.user.wallet });
      // setIsGameRunning(false);
    } catch (e) {
      console.log("Err", e);
    }
  };

  const dropChips = async (e) => {
    autoBet = numOfBets;
    if (e === "manualBet") {
      if (parseFloat(betAmount) < 0.1) {
        seterrorMessage("Bet amount should be equal or greater than 0.1");
        setTimeout(() => {
          seterrorMessage("");
        }, [3000]);
        return;
      }
      if (
        parseFloat(betAmount) === 0 ||
        betAmount === "" ||
        betAmount === null ||
        betAmount === undefined ||
        isNaN(betAmount)
      ) {
        seterrorMessage("Bet amount should be equal or greater than 0.1");
        setTimeout(() => {
          seterrorMessage("");
        }, [3000]);
        return;
      }
      // if (isGameRunning) {
      //   window.parent.postMessage("gameRunning", "*");
      //   return;
      // }

      if (!testMode && !user) {
        window.parent.postMessage("loginRequest", "*");
        return;
      }

      if (!testMode) {
        if (user && user.wallet < betAmount) {
          window.parent.postMessage("walletUpdate", "*");
          return;
        }
      }
      try {
        setIsGameRunning(true);
        setgameStates({
          ...gameStates,
          betdisabeled: true,
        });

        if (testMode) {
          playAudio("click");
          setNonce(0);
          addBall(0);
          setWinMultiplierBetValue(betAmount);
          return;
        }
        const res = await plinkoInstance().post("/createPlinkoGame", {
          amount: betAmount,
          risk,
          rows: gameStates.ROWS,
          clientSeed,
          mode: testMode,
        });
        playAudio("click");
        const {
          nonce,
          serverSeed,
          amount,
          transactionId,
          clientSeed: seed,
        } = res.data;
        setNonce(nonce);
        setServerSeed(serverSeed);

        addBall(nonce, serverSeed, amount, transactionId, seed, risk);
      } catch (error) {
        window.parent.postMessage(
          { type: "errorResponse", msg: error?.response?.data?.message },
          "*"
        );
        setIsGameRunning(false);
        setgameStates({
          ...gameStates,
          betdisabeled: false,
        });
      }
    }

    if (e === "autoBet" && !gameStates.betdisabeled) {
      if (parseFloat(betAmount) < 0.1) {
        seterrorMessage("Bet amount should be equal or greater than 0.1");
        setTimeout(() => {
          seterrorMessage("");
        }, [3000]);
        return;
      }

      if (
        parseFloat(betAmount) === 0 ||
        betAmount === "" ||
        betAmount === null ||
        betAmount === undefined ||
        isNaN(betAmount)
      ) {
        seterrorMessage("BBet amount should be equal or greater than 0.1");
        setTimeout(() => {
          seterrorMessage("");
        }, [3000]);
        return;
      }
      if (!testMode && !user) {
        window.parent.postMessage("loginRequest", "*");
        return;
      }
      if (!testMode) {
        if (user && user?.wallet < betAmount) {
          window.parent.postMessage("walletUpdate", "*");
          return;
        }
      }

      if (
        autoBet === 0 ||
        numOfBets === 0 ||
        isNaN(newnumOfBets) ||
        isNaN(numOfBets)
      ) {
        localStorage.setItem("activeInfinite", true);
      }
      localStorage.setItem("hide", true);
      setIsCancel(true);
      activeInfinite = localStorage.getItem("activeInfinite");
      clearChips = setInterval(async () => {
        if (autoBet > 0) {
          autoBet = autoBet - 1;
          changeNumOfBets(autoBet);
        } else {
          changeNumOfBets(autoBet);
        }
        await autoBetStarted(risk);
        if (
          (autoBet === 0 && (!activeInfinite || activeInfinite !== "true")) ||
          (!testMode && user.wallet < betAmount)
        ) {
          setIsCancel(false);
          localStorage.setItem("hide", false);
          clearInterval(clearChips);
        }
      }, 1000);
    }
  };

  const handleCancel = () => {
    setIsCancel(false);
    localStorage.setItem("hide", false);
    setIsGameRunning(false);
    changeNumOfBets(numOfBets);
    clearInterval(clearChips);
    localStorage.removeItem("activeInfinite");
    setgameStates({
      ...gameStates,
      betdisabeled: false,
    });
  };

  const handleTab = (e) => {
    setKey(e);
    if (e === "Auto") {
      localStorage.removeItem("hide");
    } else if (e === "Manual") {
      setIsCancel(false);
      changeNumOfBets(numOfBets);
      clearInterval(clearChips);
      localStorage.removeItem("activeInfinite");
      setgameStates({
        ...gameStates,
        betdisabeled: false,
      });
    }
  };

  const changeRisk = async () => {
    const nrow = gameStates.ROWS;
    const result = await plinkoInstance().post("/getWinBoxes", {
      risk: risk,
      row: nrow,
    });
    setServerWinBoxes(result?.data?.finalBoxes[0]);
  };

  const changeRows = async (e) => {
    if (e === 8 || e === 9) {
      setgameStates({
        ...gameStates,
        plinkoradius: 12,
        ROWS: Number(e),
        particleradius: 15,
      });
    } else if (e === 10 || e === 11) {
      setgameStates({
        ...gameStates,
        plinkoradius: 11,
        ROWS: Number(e),
        particleradius: 14,
      });
    } else if (e === 12 || e === 13) {
      setgameStates({
        ...gameStates,
        plinkoradius: 9.5,
        ROWS: Number(e),
        particleradius: 12,
      });
    } else if (e === 14 || e === 15) {
      setgameStates({
        ...gameStates,
        plinkoradius: 8.5,
        ROWS: Number(e),
        particleradius: 11,
      });
    } else {
      setgameStates({
        ...gameStates,
        plinkoradius: 7,
        ROWS: Number(e),
        particleradius: 8,
      });
    }
  };

  const createPlinkos = (ROWS, r) => {
    const ROW_SPACING =
      (gameStates.CANVAS_HEIGHT / ROWS) * gameStates.ROW_ADJUSTMENT;
    const COL_SPACING =
      (gameStates.CANVAS_WIDTH / (ROWS + 2)) * gameStates.COL_ADJUSTMENT;
    setgameStates({
      ...gameStates,
      COL_SPACING,
    });
    const VERTICAL_MARGIN = ROW_SPACING * 1.5;
    const HORIZONTAL_OFFSET = COL_SPACING / 2;
    let id = 0;
    let row = 2;
    for (row; row < ROWS + 2; row += 1) {
      const differ =
        (ROWS + 2 - row) * HORIZONTAL_OFFSET + gameStates.COL_ADJUSTMENT + 4;

      for (let col = 0; col <= row; col += 1) {
        const x = col * COL_SPACING + differ;
        const y = VERTICAL_MARGIN + ROWS + (row - 2) * ROW_SPACING;
        const plinko = new Plinko({ id, x, y, r });
        plinkos[id] = plinko;
        plinko.addToEngine(engine.world);
        id += 1;
        if (row === ROWS + 1 && col > 0) {
          if (gameStates?.ROWS === 8 || gameStates?.ROWS === 9) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 40, 550, 60, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 10) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 35, 550, 60, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 11) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 32, 550, 55, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 12) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 30, 550, 50, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 13) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 28, 550, 48, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 14) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 25, 550, 45, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 15) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 22, 550, 44, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 16) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 20, 550, 40, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          }
        }
      }
    }
  };

  const createEnvironment = (ROWS, r) => {
    createPlinkos(ROWS, r);
    Composite.add(engine.world, [
      // walls
      Bodies.rectangle(180, 290, 3, 600, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 120,
      }),
      Bodies.rectangle(620, 290, 3, 600, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: -120,
      }),

      Bodies.rectangle(345, 0, 3, 150, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),
      Bodies.rectangle(455, 0, 3, 150, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),
      Bodies.rectangle(25, 590, 2, 170, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),

      Bodies.rectangle(770, 600, 2, 190, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),
      Bodies.rectangle(300, 550, 1000, 10, {
        isStatic: true,
        label: "resultLine",
        render: { fillStyle: "transparent" },
        isSensor: true,
      }),
    ]);
    Events.on(engine, "collisionActive", onBodyCollision);
  };

  const init = (ROWS, r) => {
    particles = {};
    plinkos = {};
    createEnvironment(ROWS, r);
  };

  const handleSound = () => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      localStorage.setItem("sound", false);
      setVolume(false);
    } else {
      localStorage.setItem("sound", true);
      setVolume(true);
    }
  };

  useEffect(() => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      setVolume(true);
    } else {
      setVolume(false);
    }
  }, []);

  const autoManualToggle = (value) => {
    localStorage.removeItem("activeInfinite");
    localStorage.removeItem("activeInfinite");
    localStorage.removeItem("hide");
    localStorage.removeItem("testMode");
    changeNumOfBets(0);
    if (value === "manual") {
      setBetAmount(50);
      setAutoManual(!autoManual);
    } else {
      setBetAmount(50);
      setAutoManual(!autoManual);
    }
    changeNumOfBets(0);
  };

  const handleChangeNumOfBets = (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    changeNumOfBets(parseInt(newStr));
  };

  const handleTestMode = () => {
    const msg = !testMode ? "enabled" : "disabled";
    if (msg === "disabled") {
      window.parent.postMessage(
        { type: "testModeDisable", msg: msg, testMode: testMode },
        "*"
      );
    } else {
      window.parent.postMessage(
        { type: "testMode", msg: msg, testMode: testMode },
        "*"
      );
    }
    setTestMode(!testMode);
    localStorage.setItem("testmode", !testMode);
  };

  const handleBetAmount = (e, value) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    if (value === "autoBetAmount") {
      setBetAmount(parseFloat(newStr));
    } else {
      setBetAmount(parseFloat(newStr));
    }
  };

  useEffect(() => {
    createCanvas();
    init(gameStates.ROWS, gameStates.plinkoradius);
    return () => {
      World.clear(engine.world, true);
      Engine.clear(engine);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStates?.ROWS]);

  useEffect(() => {
    if (risk && gameStates.ROWS) {
      changeRisk();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [risk, gameStates?.ROWS]);
  const hide = localStorage.getItem("hide");
  const isHide = hide && hide === "true" ? true : false;
  useEffect(() => {
    window.parent.postMessage(
      { type: "isGameRunning", isGameRunning: isGameRunning },
      "*"
    );
  }, [isGameRunning]);
  const handleRisk = (value) => {
    setRisk(value);
  };

  return (
    <div className="plinko-game-page">
      <div className="container">
        <div className="plinko-grid">
          <div className="plinko_tab_sidepanel">
            <div className="tab-section">
              <Tabs
                defaultActiveKey="Manual"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(e) => handleTab(e)}
              >
                <Tab eventKey="Manual" title="Manual">
                  <ManualTabToBet
                    gameStates={gameStates}
                    dropChips={dropChips}
                    changeRows={changeRows}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount}
                    risk={risk}
                    autoManualToggle={autoManualToggle}
                    autoManual={autoManual}
                    handleSound={handleSound}
                    isGameRunning={isGameRunning}
                    handleBetAmount={handleBetAmount}
                    errorMessage={errorMessage}
                    handleRisk={handleRisk}
                  />
                </Tab>
                <Tab eventKey="Auto" title="Auto">
                  <AutoTabToBet
                    gameStates={gameStates}
                    dropChips={dropChips}
                    changeRows={changeRows}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount}
                    risk={risk}
                    handleRisk={handleRisk}
                    autoManualToggle={autoManualToggle}
                    autoManual={autoManual}
                    handleChangeNumOfBets={handleChangeNumOfBets}
                    numOfBets={numOfBets}
                    handleSound={handleSound}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    newnumOfBets={newnumOfBets}
                    activeAutoBets={activeAutoBets}
                    changeBetCount={changeBetCount}
                    setActiveAutoBets={true}
                    changeNumOfBets={changeNumOfBets}
                    setIsCancel={setIsCancel}
                    isCancel={isCancel}
                    handleBetAmount={handleBetAmount}
                    errorMessage={errorMessage}
                    isGameRunning={isGameRunning}
                    handleCancel={handleCancel}
                  />
                </Tab>
              </Tabs>

              <div className="auto-bet auto-bet-mode">
                <p>Just for fun</p>
                <div className={`toggle-section`}>
                  <input
                    type="checkbox"
                    id="lb3"
                    disabled={isGameRunning || isHide}
                    checked={testMode}
                    onChange={handleTestMode}
                  />
                  <Form.Label
                    htmlFor="lb3"
                    className={`${isGameRunning || isHide ? "disabled" : ""}`}
                  />
                </div>
              </div>
            </div>
            {/* {user && ( */}
            <div className="plinko-game-footer">
              <div className="volume-btn1">
                {volume ? (
                  <i
                    className="las la-volume-up"
                    role="presentation"
                    onClick={handleSound}
                  />
                ) : (
                  <i
                    className="las la-volume-off"
                    role="presentation"
                    onClick={handleSound}
                  />
                )}
              </div>
              {user && (
                <div className="plinko-game-btns">
                  <div className="fairness-btn" onClick={handlestatsShow}>
                    <img src={livestats} alt="livestats" />
                    <span>Live stats</span>
                  </div>
                  <div className="fairness-btn" onClick={handleShow}>
                    <img src={fairnessicon} alt="fairness" />
                    <span>Fairness</span>
                  </div>
                </div>
              )}
            </div>
            {/* )} */}
          </div>

          <div className="main_section">
            <div className="canvas-container">
              <div id="plinko" />
              <div className={`pegs rows${gameStates.ROWS}`}>
                <div className="pegs_wrapper">
                  {serverWinBoxes?.map((el, i) => (
                    <div
                      className={`peg ${""} peg${i + 1} ${""}`}
                      key={i}
                      style={{
                        top: gameStates[`peg${i + 1}`] ? "10px" : "0px",
                      }}
                      test={`peg${i + 1}`}
                      test2={gameStates[`peg${i + 1}`]}
                    >
                      <span
                        className={`${
                          risk === "High" ? "pegtexthigh" : ""
                        } pegtext`}
                      >
                        {el}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {winTextResult && (
                <div className="gameResultpopup">
                  <p
                    className={parseFloat(winTextResult) >= 1 ? "win" : "lose"}
                  >
                    {winMultiplierBetValue &&
                      (
                        winMultiplierBetValue * parseFloat(winTextResult)
                      ).toFixed(1)}
                  </p>
                </div>
              )}
              {lastMultipliers && (
                <div className="resultStatsbar-section">
                  {lastMultipliers.map((el, i) => (
                    <p
                      key={i}
                      style={{
                        top: `${i * 40}px`,
                        opacity: 1,
                        left: 0,
                        transition: "all ease 0.9s",
                      }}
                      className={`${
                        toggleAnimation && lastMultipliers?.length - 1 === 0
                          ? "wow animate__animated animate__slideInDown"
                          : ""
                      }`}
                    >
                      {el}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <audio className="audio-click" muted={!volume}>
            <source src={click}></source>
          </audio>
          <audio className="audio-win" muted={!volume}>
            <source src={win}></source>
          </audio>
        </div>

        <Modal
          show={fairness}
          onHide={handleCloseFairness}
          centered
          className="fairness-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Fairness</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FainessPopup
              clientSeed={clientSeed}
              serverSeed={serverSeed}
              finalResult={finalResult}
              fairnessCalculate={fairnessCalculate}
              nonce={serverNonce}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={stats}
          onHide={handlestatsClose}
          centetransparent
          className="fairness-popup"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Live Stats</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LiveStats firstTimeBalance={firstTimeBalance} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default GameEngine;
