import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Chart from "react-apexcharts";
import { plinkoInstance } from "../../config/axiosConfig";
const capitalizeFirstLowercaseRest = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
let allProfits = [];
const LiveStats = () => {
  const [statsData, setStatsData] = useState([]);
  const getStatsData = async () => {
    await plinkoInstance()
      .post("/getLiveStats")
      .then((response) => {
        setStatsData([response?.data?.bettingData]);
      });
  };

  useEffect(() => {
    getStatsData();
  }, []);

  useEffect(() => {
    if (statsData) {
      statsData[0]?.forEach((el) => allProfits.push(el?.profit || 0));
    }
  }, [statsData]);

  const series = [
    {
      name: "Profit",
      data: allProfits,
    },
  ];
  const options = {
    chart: {
      fontFamily: "Montserrat",
      zoom: {
        enabled: false,
      },
      type: "area",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      area: {
        fillTo: "origin",
      },
    },
    colors: ["#fff"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    xaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          return value;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: (value) => {
          return value;
        },
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      fillSeriesColor: false,
      theme: ["#000"],
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        colors: ["#fff"],
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },

      marker: {
        show: true,
      },
      items: {
        display: "block",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 50,
      },
    },
  };

  function addValuesWithKey(arr, key) {
    const totalSum =
      arr && arr?.reduce((acc, curr) => acc + (curr[key] || 0), 0);
    return totalSum;
  }
  console.log("stats===>", statsData);
  const totalSum = addValuesWithKey(statsData[0], "amount");
  const totalProfit = addValuesWithKey(statsData[0], "profit");
  function getMonthName(month) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString("default", { month: "long" });
    return monthName;
  }
  const getDate = (d) => {
    const date = new Date(d);
    const day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // convert to 12-hour format
    month = getMonthName(month);
    return `${day} ${month} ${year} ${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${ampm}`;
  };

  let totalWinValue = 0;
  let totalLossValue = 0;
 
    // Loop through each object in statsData array
    statsData &&
      statsData[0]?.length > 0 &&
      statsData[0].forEach((item) => {
        // Check if profit is greater than 0
        if (item.profit >= 0) {
          // Increment totalWinValue
          totalWinValue++;
        } else {
          // Increment totalLossValue
          totalLossValue++;
        }
      });
    // eslint-disable-next-line


  return (
    <div className="live-stats-content">
      <div className="live-stats-heading">
        <div className="stats-top-status">
          <p>Wagered</p>
          <span>{totalSum && totalSum?.toFixed(2)}</span>
        </div>
        <div className="stats-top-status">
          <p>Win</p>
          <span>{totalWinValue && totalWinValue}</span>
        </div>
        <div className="stats-top-status">
          <p>Loss</p>
          <span>{totalLossValue && totalLossValue}</span>
        </div>
        <div className="stats-top-status">
          <p>Profit</p>
          <span>
            <p className={totalProfit && totalProfit > 0 ? "" : "loss-amt"}>
              {totalProfit && totalProfit?.toFixed(2)}
            </p>
          </span>
        </div>
      </div>
      <Chart
        className="statsChart"
        options={options}
        series={series}
        type="area"
        width="100%"
        height="300px"
      />

      <div className="live-stats-table">
        <div className="dice-history">
          <Table striped bordered variant="dark" responsive>
            <thead>
              <tr>
                <th>Game</th>
                <th>Time</th>
                <th>Wagered </th>
                <th>Multiplier</th>
                <th>profit</th>
              </tr>
            </thead>
            <tbody className="tbody_slot">
              {statsData &&
                statsData[0]?.map((el) => (
                  <tr>
                    <td>{capitalizeFirstLowercaseRest(el?.gameType)}</td>
                    <td>{getDate(el?.createdAt)}</td>
                    <td>{el?.amount}</td>
                    <td>{el?.multiplier}</td>
                    <td
                      className={`${
                        el?.profit?.toString().includes("-") ||
                        el?.profit === undefined
                          ? "loss-amt"
                          : "win-amt"
                      }`}
                    >
                      {el?.profit ? el.profit.toFixed(2).replace("-", "") : 0.0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="showing_num_records">Showing latest 20 records</div>
    </div>
  );
};

export default LiveStats;
