import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
function ManualTabToBet({
  setBetAmount,
  betAmount,
  isPlaceBet,
  handleDrop,
  dropChips,
  changeRows,
  risk,
  isGameRunning,
  handleBetAmount,
  errorMessage,
  gameStates,
  handleRisk,
}) {
  const handleBet = (amt, type) => {
    if (type === "half") {
      if (amt < 0.1) {
        setBetAmount(0.1);
      } else {
        setBetAmount(amt);
      }
    }
    if (type === "double") {
      setBetAmount(amt > 500 ? 500 : amt);
    }
    if (type === "4x") {
      setBetAmount(amt > 500 ? 500 : amt);
    }
    if (type === "max") {
      setBetAmount(500);
    }
  };

  return (
    <div className="plinko-place-bet">
      <div className="total_bet">
        <div className="total_bet_input">
          <p>Bet Amount</p>
          <input
            type="number"
            disabled={isGameRunning}
            value={betAmount}
            onChange={(e) => handleBetAmount(e, "manualBetAmount")}
          />
          {errorMessage && <p className="text-danger">{`${errorMessage}`}</p>}
        </div>

        <div className="multiplier-btn">
          <Button
            className="multiplier"
            size="medium"
            color="primary"
            disabled={isGameRunning}
            variant="contained"
            onClick={() => handleBet(betAmount / 2, "half")}
          >
            ½
          </Button>
          {/* <ThemeButton className="multiplier" text="½"
           handleEvent={() => handleBet(betAmount / 2)} /> */}
          <Button
            className="multiplier"
            size="medium"
            color="primary"
            disabled={isGameRunning}
            variant="contained"
            onClick={() => handleBet(betAmount * 2, "double")}
          >
            2×
          </Button>
          <Button
            className="multiplier"
            size="medium"
            color="primary"
            disabled={isGameRunning}
            variant="contained"
            onClick={() => handleBet(betAmount * 4, "4x")}
          >
            4×
          </Button>
          <Button
            className="multiplier"
            size="medium"
            color="primary"
            disabled={isGameRunning}
            variant="contained"
            onClick={() => handleBet(500, "max")}
          >
            MAX
          </Button>
        </div>
        <div className="auto-bet-section">
          <div className="place-bet-section">
            {isPlaceBet ? (
              <Button
                className="btn-gold plinko-bet-btn"
                size="medium"
                color="primary"
                variant="contained"
                disabled={betAmount === 0}
                onClick={() => handleDrop()}
              >
                Drop
              </Button>
            ) : (
              <Button
                className="btn-gold plinko-bet-btn"
                size="medium"
                color="primary"
                variant="contained"
                disabled={betAmount === 0}
                onClick={() => dropChips("manualBet")}
              >
                Place Bet
              </Button>
            )}
          </div>
        </div>
        <div className="no-of-rows">
          <Dropdown>
            <p>Risk</p>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              disabled={isGameRunning}
            >
              {risk}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleRisk("Low")}>
                Low
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleRisk("High")}>
                High
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <p>Row</p>
            <Dropdown.Toggle
              disabled={isGameRunning}
              variant="success"
              id="dropdown-basic"
            >
              {gameStates?.ROWS}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeRows(8)}>8</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(9)}>9</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(10)}>10</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(11)}>11</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(12)}>12</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(13)}>13</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(14)}>14</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(15)}>15</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(16)}>16</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

ManualTabToBet.defaultProps = {
  setBetAmount: () => {},
  betAmount: 0,
  dropChips: () => {},
  handleDrop: () => {},
  isPlaceBet: false,
};

ManualTabToBet.propTypes = {
  setBetAmount: PropTypes.func,
  betAmount: PropTypes.number,
  dropChips: PropTypes.func,
  handleDrop: PropTypes.func,
  isPlaceBet: PropTypes.bool,
  changeRows: PropTypes.func.isRequired,
  lines: PropTypes.number.isRequired,
  setRisk: PropTypes.func.isRequired,
  risk: PropTypes.string,
  autoManualToggle: PropTypes.func,
  autoManual: PropTypes.bool,
};

export default ManualTabToBet;
