import React, { useState } from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
// import { Tabs, Tab } from "react-bootstrap";
import copy from "../../assets/images/game/copy-icon.png";
// import arrow from "../../assets/images/game/arrow-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// seeds
const FainessPopup = (data) => {
  const [copied, setCopied] = useState("");

  return (
    <div className="fainess-popup-content">
      <div className="plinko-tab">
        <Tabs defaultActiveKey="seeds" id="uncontrolled-tab-example">
          <Tab eventKey="seeds" title="Seeds" className="helo">
            <Seeds data={data} setCopied={setCopied} copied={copied} />
          </Tab>
          <Tab eventKey="varify" title="Verify">
            <Varify
              data={data}
              //   seeds={seeds}
              //   finalResult={seeds.finalResult}
              //   fairnessCalculate={seeds.fairnessCalculate}
              //   handleSetNonce={seeds.handleSetNonce}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default FainessPopup;

const Seeds = ({ data, setCopied, copied }) => {
  const handleCopy = (value) => {
    setCopied(value);
  };

  return (
    <div className="seeds-content">
      <Form>
        <div className="tab-box">
          <Form.Group>
            <Form.Label>Active Client Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control type="text" value={data?.clientSeed} />
              <div className="plinko-input-btn">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "clientSeed")
                  }
                >
                  <CopyToClipboard
                    text={data?.clientSeed}
                    onCopy={() => handleCopy("clientSeed")}
                  >
                    <Button className="plinko-btn-copy">
                      <img src={copy} alt="copy" />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Active Server Seed (Hashed)</Form.Label>
            <div className="plinko-input">
              <Form.Control type="text" value={data?.serverSeed} />
              <div className="plinko-input-btn">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "serverSeed")
                  }
                >
                  <CopyToClipboard
                    text={data?.serverSeed}
                    onCopy={() => handleCopy("serverSeed")}
                  >
                    <Button className="plinko-btn-copy">
                      <img src={copy} alt="copy" />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Total bets made with pair</Form.Label>
            <div className="plinko-input">
              <Form.Control type="number" value={data?.nonce} />
              <div className="plinko-input-btn">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderTooltip(props, copied, "nonce")}
                >
                  <CopyToClipboard
                    text={data?.nonce}
                    onCopy={() => handleCopy("nonce")}
                  >
                    <Button className="plinko-btn-copy">
                      <img src={copy} alt="copy" />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
      {/* <div className='seed-bottom'>
        <h4>Rotate Seed Pair</h4>
        <Form.Group>
          <Form.Label>New Client Seed</Form.Label>
          <div className='plinko-input plinko-nobg-input'>
            <Form.Control type='text' />
            <div className='plinko-change'>
              <Button>Change</Button>
            </div>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Next Server Seed (Hashed)</Form.Label>
          <div className='plinko-input'>
            <Form.Control type='number' />
            <div className='plinko-input-btn'>
              <Button className='plinko-btn-copy'>
                <img src={copy} alt='copy' />
              </Button>
            </div>
          </div>
        </Form.Group>
      </div> */}
    </div>
  );
};

const Varify = (data) => {
  return (
    <div className="varify-content">
      {/* <div className="varify-box"> */}
      {/* hjhj */}
      {/* <span>{seeds.finalResult}x</span> */}
      {/* </div> */}

      <Form>
        <div className="tab-box">
          <Form.Group>
            <div className="tab-box-game">
              <Form.Label>Game</Form.Label>
              <select defaultValue={"Plinko"}>
                <option>plinko</option>
                <option>Slot</option>
                <option>Crash</option>
                <option>Plinko</option>
              </select>
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Client Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control type="text" value={data.data.clientSeed} />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Server Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control type="text" value={data.data.serverSeed} />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className="plinko-input">
              <Form.Control type="number" value={data.data.nonce} />
              {/* <div className="plinko-input-btn arrow-bottom">
                <Button className="plinko-btn-copy">
                  <img src={arrow} alt="arrow" />
                </Button>
              </div> */}
              {/* <div className="plinko-input-btn arrow-top">
                <Button className="plinko-btn-copy">
                  <img src={arrow} alt="arrow" />
                </Button>
              </div> */}
            </div>
          </Form.Group>
        </div>
      </Form>
      <div className="varify-bottom-text" onClick={data.data.fairnessCalculate}>
        <span className="calcution-btn">View Calculation Breakdown</span>
      </div>
      <div className="final_result"> 
      
        {`Final result: `}
        {data.data.finalResult}
        {`x`}
      </div>
    </div>
  );
};
const renderTooltip = (props, copied, type) => (
  <Tooltip id="button-tooltip" {...props} placement="top">
    {copied === type ? "Copied" : "Copy"}
  </Tooltip>
);
