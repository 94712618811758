/* eslint-disable react-hooks/exhaustive-deps */
// import GameEngine from './components/plinko/GameEngine'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
// import { Game } from './pages/plinko/game';
import { useAuthStore } from "./store/auth";
import { useEffect } from "react";
import GameEngine from "./components/plinko/GameEngine";

function App() {
  const {  user, signIn } = useAuthStore(state => state);
  useEffect(() => {
    if(!user){
      signIn()
    }
  },[])
  return (
    <div className="App">
      <video  id="background-video" autoPlay loop muted>
        <source src="https://cdn.dribbble.com/userupload/3820544/file/original-d9f7f37de39ab8615d574d3664cb66d2.mp4" type="video/mp4" />
        </video>
      <GameEngine />
      {/* <Game /> */}
      <ToastContainer />
    </div>
  );
}

export default App;
