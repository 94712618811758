
// multiplierSoundEffects
import multiplierBest from '../../../../assets/sound/sounds/multiplier-best.wav'
import multiplierGood from '../../../../assets/sound/sounds/multiplier-good.wav'
import multiplierLow from '../../../../assets/sound/sounds/multiplier-low.wav'
import multiplierRegular from '../../../../assets/sound/sounds/multiplier-regular.wav'

// images
import multiplier110 from '../../../../assets/images/multipliers/multiplier110.png'
import multiplier88 from '../../../../assets/images/multipliers/multiplier88.png'
import multiplier41 from '../../../../assets/images/multipliers/multiplier41.png'
import multiplier33 from '../../../../assets/images/multipliers/multiplier33.png'
import multiplier25 from '../../../../assets/images/multipliers/multiplier25.png'
import multiplier18 from '../../../../assets/images/multipliers/multiplier18.png'
import multiplier15 from '../../../../assets/images/multipliers/multiplier15.png'
import multiplier10 from '../../../../assets/images/multipliers/multiplier10.png'
import multiplier5 from '../../../../assets/images/multipliers/multiplier5.png'
import multiplier3 from '../../../../assets/images/multipliers/multiplier3.png'
import multiplier2 from '../../../../assets/images/multipliers/multiplier2.png'
import multiplier1 from '../../../../assets/images/multipliers/multiplier1.png'
import multiplier1dot5 from '../../../../assets/images/multipliers/multiplier1.5.png'
import multiplier0dot5 from '../../../../assets/images/multipliers/multiplier0.5.png'
import multiplier0dot3 from '../../../../assets/images/multipliers/multiplier0.3.png'

const multiplierSounds = {
  110: multiplierBest,
  88: multiplierBest,
  41: multiplierBest,
  33: multiplierBest,
  25: multiplierBest,
  18: multiplierGood,
  15: multiplierGood,
  10: multiplierGood,
  5: multiplierGood,
  3: multiplierRegular,
  2: multiplierRegular,
  1.5: multiplierRegular,
  1: multiplierRegular,
  0.5: multiplierLow,
  0.3: multiplierLow
}

const multipliers = {
  110: {
    label: 'block-110',
    sound: multiplierBest,
    num: multiplier110
  },
  88: {
    label: 'block-88',
    sound: multiplierBest,
    num: multiplier88
  },
  41: {
    label: 'block-41',
    sound: multiplierBest,
    num: multiplier41
  },
  33: {
    label: 'block-33',
    sound: multiplierBest,
    num: multiplier33
  },
  25: {
    label: 'block-25',
    sound: multiplierBest,
    num: multiplier25
  },
  18: {
    label: 'block-18',
    sound: multiplierGood,
    num: multiplier18
  },
  15: {
    label: 'block-15',
    sound: multiplierGood,
    num: multiplier15
  },
  10: {
    label: 'block-10',
    sound: multiplierGood,
    num: multiplier10
  },
  5: {
    label: 'block-5',
    sound: multiplierGood,
    num: multiplier5
  },
  3: {
    label: 'block-3',
    sound: multiplierRegular,
    num: multiplier3
  },
  2: {
    label: 'block-2',
    sound: multiplierRegular,
    num: multiplier2
  },
  1.5: {
    label: 'block-1.5',
    sound: multiplierRegular,
    num: multiplier1dot5
  },
  1: {
    label: 'block-1',
    sound: multiplierRegular,
    num: multiplier1
  },
  0.5: {
    label: 'block-0.5',
    sound: multiplierLow,
    num: multiplier0dot5
  },
  0.3: {
    label: 'block-0.3',
    sound: multiplierLow,
    num: multiplier0dot3
  }
}



export function getMultiplier(value) {
  return multipliers[value]
}

export const multiplyBlocks16Lines = [
  getMultiplier(110),
  getMultiplier(41),
  getMultiplier(10),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(10),
  getMultiplier(41),
  getMultiplier(110)
]

export const multiplyBlocks15Lines = [
  getMultiplier(88),
  getMultiplier(18),
  getMultiplier(10),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(10),
  getMultiplier(18),
  getMultiplier(88)
]
export const multiplyBlocks14Lines = [
  getMultiplier(41),
  getMultiplier(15),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(15),
  getMultiplier(41)
]
export const multiplyBlocks13Lines = [
  getMultiplier(41),
  getMultiplier(15),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(15),
  getMultiplier(41)
]
export const multiplyBlocks12Lines = [
  getMultiplier(33),
  getMultiplier(10),
  getMultiplier(3),
  getMultiplier(2),
  getMultiplier(1.5),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1.5),
  getMultiplier(2),
  getMultiplier(3),
  getMultiplier(10),
  getMultiplier(33)
]
export const multiplyBlocks11Lines = [
  getMultiplier(25),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(2),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(2),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(25)
]
export const multiplyBlocks10Lines = [
  getMultiplier(25),
  getMultiplier(5),
  getMultiplier(2),
  getMultiplier(1.5),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1.5),
  getMultiplier(2),
  getMultiplier(5),
  getMultiplier(25)
]
export const multiplyBlocks9Lines = [
  getMultiplier(10),
  getMultiplier(5),
  getMultiplier(2),
  getMultiplier(1.5),
  getMultiplier(0.3),
  getMultiplier(0.3),
  getMultiplier(1.5),
  getMultiplier(2),
  getMultiplier(5),
  getMultiplier(10)
]
export const multiplyBlocks8Lines = [
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5)
]

export const multiplyBlocksByLinesQnt = {
  8: multiplyBlocks8Lines,
  9: multiplyBlocks9Lines,
  10: multiplyBlocks10Lines,
  11: multiplyBlocks11Lines,
  12: multiplyBlocks12Lines,
  13: multiplyBlocks13Lines,
  14: multiplyBlocks14Lines,
  15: multiplyBlocks15Lines,
  16: multiplyBlocks16Lines
}

export function getMultiplierByLinesQnt(value) {
  return multiplyBlocksByLinesQnt[value]
}

export function getMultiplierSound(value) {
  return multiplierSounds[value]
}